import { IUser } from '../user/user.model';
import { ICompany } from '../company/company.model';
import { IAddress } from '../company/address.model';
import { IContact } from './contact.model';
import { IPosition } from './position.model';
import { IConversation } from '../conversation/conversation.model';
import { IOffer } from './offer.model';
import { IAttachment } from './attachment.model';
import { ISurvey } from './survey.model';

export enum ESurveyType {
    Inner = 'Innenbesichtigung',
    Outer = 'Aussenbesichtigung',
}

export enum EFormType {
    'Standard' = 'default',
    'Sachwertrichtlinie' = 'swr',
    'DZ HYP' = 'hyp',
}

export enum EFormTypeInvert {
    'default' = 'Standard',
    'swr' = 'Sachwertrichtlinie',
    'hyp' = 'DZ HYP',
}

export enum EContractState {
    InCreation = 'InCreation',
    Submitted = 'Submitted',
    Surveying = 'Surveying',
    InValidation = 'InValidation',
    Signing = 'Signing',
    Done = 'Done',
}

export enum EContractStateLabels {
    InCreation = 'In Erstellung',
    Submitted = 'Offen',
    Surveying = 'Aktiv',
    InValidation = 'In Prüfung',
    Signing = 'Zur Bestätigung',
    Done = 'Abgeschlossen',
}

export const ActiveContracts: EContractState[] = [EContractState.Surveying];

export enum EApiSource {
    'Investor' = 'Investor',
    'Business' = 'Geschäftskunde',
    'APP' = 'App',
    'endkunde' = 'Endkunde',
    'Private' = 'Privat',
}

export interface IContract {
    id: string;

    accessible: boolean;

    fileNumber: string;

    archived: boolean;

    infoMailAP1?: string | null;
    infoMailAP2?: string | null;

    surveyDateConfirmationAP?: string | null;

    canceled: boolean;

    creator: IUser;

    contractType: string;

    state: EContractState;

    company?: ICompany;

    fee?: number;

    deadlineSV?: string;

    deadlineEK?: string;

    note1?: string;

    note2?: string;

    validator?: IUser;

    noteCorrection?: string;

    address?: IAddress;

    contact1?: IContact;

    contact2?: IContact;

    contact1InUse: boolean;

    contact2InUse: boolean;

    position: IPosition;

    conversation: IConversation;

    express: boolean;

    minUnits?: number;

    maxUnits?: number;

    objectType?: string;

    surveyor?: IUser;

    surveyDate?: string;

    formType: string;

    offers?: IOffer[];

    surveys?: ISurvey[];

    attachments?: IAttachment[];

    inCorrection?: boolean;

    created?: string;

    saved?: string;

    apiSource: string | null;

    downloaded?: string;

    fromAPI?: boolean;

    favoured?: string[];
}

export interface IContractCollection {
    [key: string]: IContract;
}
