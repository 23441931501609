import { ICompany } from "./company.interface";

export enum EContractType {
  Inner = "Innenbesichtigung",
  Outer = "Aussenbesichtigung",
  Bautenstandsbericht = "Bautenstandsbericht",
}

export enum EObjectType {
  "ETW" = "Eigentumswohnung",
  "EFH" = "Einfamilienhaus",
  "ZFH" = "Zweifamilienhaus",
  "RH" = "Reihenhaus",
  "DHH" = "Doppelhaushälfte",
  "MFH" = "Mehrfamilienhaus",
  "WohnUndGeschaeftshaus" = "Wohn- und Geschäftshaus",
  "Gewerbeobjekt" = "Gewerbeobjekt",
  "unbebautesGrundstueck" = "unbebautes Grundstück",
  "Bauernhof" = "Bauernhof",
  "sonstiges" = "sonstiges",
}

export enum ESurveyType {
  Inner = "Innenbesichtigung",
  Outer = "Aussenbesichtigung",
}

export enum EFormType {
  "Standard" = "default",
  "Sachwertrichtlinie" = "swr",
  "DZ HYP" = "hyp",
}

export enum EContractState {
  InCreation = "InCreation",
  Submitted = "Submitted",
  Surveying = "Surveying",
  InValidation = "InValidation",
  Signing = "Signing",
  Done = "Done",
}

export interface IAddress {
  street?: string | null;
  houseNo?: string | null;
  city?: string | null;
  postalCode?: string | null;
  country?: string | null;
  note?: string | null;
}

export interface IContact {
  title?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  mobile?: string | null;
  mail?: string | null;
  position?: string | null;
}

export interface IPosition {
  corrected?: boolean | null;
  latitude?: number | null;
  longitude?: number | null;
}

export interface IOffer {
  surveyorID: number;
  amount: number;
  applied: string;
  confirmed: string | null;
}

export interface ISurvey {
  surveyID: number;
  type: ESurveyType;
  name: string;
  saved: string | null;
  deleted: string | null;
  form: string;
  photo: string;
}

export interface ISurveyForm {
  global: {
    [key: string]: string | string[];
  };
  showElements: ISurveyFormShowWhen;
  checkId: string;
  meta: {
    lastSaved: string;
    progress: number;
  };
}

export interface ISurveyFormShowWhenItem {
  [element: string]: string | string[];
}

export interface ISurveyFormShowWhen {
  [element: string]: ISurveyFormShowWhenItem;
}

export interface IAttachment {
  attachmentID: number;
  fileName: string;
  displayable: boolean;
  mimeType: string;
}

export interface IContractCreate {
  creatorID?: number;
  fileNumber?: string;
  contractType?: EContractType;
  formType?: EFormType;
  companyID?: number;
  address?: IAddress;
  contact1?: IContact;
  contact2?: IContact;
  express?: boolean;
  minUnits?: number;
  maxUnits?: number;
  objectType?: string;
  fromAPI?: boolean;
  apiSource?: string;
  notes?: string;
  notes2?: string;
}

export interface IContractCreateWebsiteBodyAttachment {
  fileName: string;
  mimeType: string;
  base64: string;
}

export interface IContractCreateWebsiteBody {
  fileNumber: string;
  objectType: string;
  residentialUnits: string | number;
  street: string;
  hNumber: string;
  zip: string;
  city: string;
  country: string;
  contractType: string;
  express: string | boolean;
  extras: { label: string; value: string }[] | string[];
  extras1: string[];
  extras2: string[];
  apTitle: string;
  apFirstName: string;
  apLastName: string;
  apPosition: string;
  apPhone: string;
  apMobile: string;
  apEmail: string;
  apNotes: string;
  customerID?: string | number;
  clientTitle?: string;
  clientName?: string;
  clientEmail?: string;
  clientPhone?: string;
  surveyor?: string;
  attachments: IContractCreateWebsiteBodyAttachment[];
}

export interface IContractUpdate extends IContractCreate {
  contractID?: number;
  updaterID?: number;
  fee?: number;
  deadline?: string;
  completion?: string;
  validatorID?: number;
  position?: IPosition;
  maxUnits?: number;
  surveyDate?: string;
}

export interface IContract {
  contractID: number;
  fileNumber: string;
  created: string;
  saved: string;
  archived: boolean;
  canceled: boolean;
  creatorID: number;
  contractType: EContractType;
  state: EContractState;
  inCorrection: boolean | null;
  fee: number | null;
  deadline: string | null;
  completion: string | null;
  fromAPI: boolean;
  apiSource?: string;
  note1: string | null;
  note2: string | null;
  validatorID: number | null;
  noteCorrection: string | null;
  address: IAddress;
  position: IPosition;
  contact1: IContact;
  contact2: IContact;
  conversationID: number | null;
  express: boolean | null;
  minUnits: number | null;
  maxUnits: number | null;
  objectType: string | null;
  surveyorID: number | null;
  surveyDate: string | null;
  formType: string;
  offers?: IOffer[];
  surveys?: ISurvey[];
  attachments?: IAttachment[];
  companyID?: number | null;
  company?: ICompany;
  downloaded: string | null;
  favoured: number[];
  infoMailAP1?: string | null;
  infoMailAP2?: string | null;
  surveyDateConfirmationAP?: string;
}

export enum EContractContactTitle {
  Mrs = "Frau",
  Mr = "Herr",
  Firma = "Firma",
  None = "",
}

export enum EContractContactTitleNew {
  Frau = "Frau",
  Herr = "Herr",
  Firma = "Firma",
  none = "",
}
