import { IContact } from '../interfaces/contract/contact.model';
import { contractKeys } from '../hooks/contracts/queryKeyFactory';
import { storeHasEntry } from './offline';
import localforage from 'localforage';
import { EContractState, IContract } from '../interfaces/contract/contract.model';
import dayjs from 'dayjs';
import { ISurvey } from '../interfaces/contract/survey.model';

export const contractHasSecondContact = (contact?: IContact): boolean => {
    for (const value of Object.values(contact ?? {})) {
        if (value) {
            return true;
        }
    }
    return false;
};

export interface ILocalTimestamps {
    [hashName: string]: string;
}

export const lfContractMetaStore = localforage.createInstance({ version: 4, name: 'contractMeta' });

// TODO OBSOLETE; remove
export const contractIsInQueryStore = (contractId: string): Promise<boolean> => {
    return storeHasEntry(`["${contractKeys.single(contractId).join('","')}"]`);
};

export const getOpenedContracts = async (): Promise<string[]> => {
    return lfContractMetaStore.getItem<string[]>('opened').then((res) => res ?? []);
};

export const setOpenedContract = async (contractId: string): Promise<void> => {
    const ids = await getOpenedContracts();

    if (!ids.includes(contractId)) {
        ids.push(contractId);
        await lfContractMetaStore.setItem('opened', ids);
    }
};

export const contractIsEditable = (contract: IContract): boolean => {
    return contract.state === EContractState.Surveying;
};

export const contractIsArchivable = (state: EContractState): boolean => {
    return [EContractState.Signing, EContractState.Done].includes(state);
};
export const contractIsUnArchivable = (contract: IContract): boolean => {
    return [EContractState.Signing, EContractState.Done, EContractState.InValidation].includes(
        contract.state
    );
};

export const contractIsRevokable = (state: EContractState): boolean => {
    return [
        EContractState.InCreation,
        EContractState.Submitted,
        EContractState.Surveying,
        EContractState.InValidation,
    ].includes(state);
};

export const formName = (contractId: string, survey: ISurvey): string => {
    return `form-${contractId}-${survey.id}-${survey.name}`;
};

export const photoVaultName = (contractId: string, survey: ISurvey): string => {
    return `pv-${contractId}-${survey.id}-${survey.name}`;
};

export const formNameFile = (contractId: string, survey: ISurvey): string => {
    return `${formName(contractId, survey)}.json`;
};

export const photoVaultNameFile = (contractId: string, survey: ISurvey): string => {
    return `${photoVaultName(contractId, survey)}.json`;
};

/*
schaut erstmal nur nach ob einer der surveys auf dem server neu sind (vergleicht saved/deleted mit lokalen form stand)
 */
export const serverContractSurveyIsNewer = (
    contract: IContract,
    localFormTimestamps: ILocalTimestamps,
    localPhotoTimestamps: ILocalTimestamps
): boolean => {
    if (contract.surveys?.length) {
        for (const survey of contract.surveys) {
            const hashName = formName(contract.id, survey);

            if (!localFormTimestamps[hashName]) {
                return true;
            }

            const surveySavedIsNewer =
                survey.saved && dayjs(survey.saved).isAfter(dayjs(localFormTimestamps[hashName]));

            if (surveySavedIsNewer) {
                return true;
            }

            const surveyDeletedIsNewer =
                survey.deleted &&
                dayjs(survey.deleted).isAfter(dayjs(localFormTimestamps[hashName]));

            if (surveyDeletedIsNewer) {
                return true;
            }

            const pvHashName = photoVaultName(contract.id, survey);

            if (!localPhotoTimestamps[pvHashName]) {
                return true;
            }

            const surveyPhotoIsNewer =
                survey.saved &&
                dayjs(survey.saved).isAfter(dayjs(localPhotoTimestamps[pvHashName]));

            if (surveyPhotoIsNewer) {
                return true;
            }
        }
    }

    return false;
};

export const getLocalFormTimestamps = async (): Promise<ILocalTimestamps> => {
    return lfContractMetaStore.getItem<ILocalTimestamps>('formTimestamps').then((timestamps) => {
        return timestamps ?? {};
    });
};

export const setLocalFormTimestamp = async (hashName: string, time?: string) => {
    return getLocalFormTimestamps().then((current) => {
        const newStore: ILocalTimestamps = current ? current : {};
        newStore[hashName] = time ?? dayjs().toISOString();
        return lfContractMetaStore.setItem('formTimestamps', newStore);
    });
};

export const getLocalPhotoTimestamps = async (): Promise<ILocalTimestamps> => {
    return lfContractMetaStore.getItem<ILocalTimestamps>('photoTimestamps').then((timestamps) => {
        return timestamps ?? {};
    });
};

export const removeLocalPhotoTimestamps = async (): Promise<ILocalTimestamps> => {
    return lfContractMetaStore.getItem<ILocalTimestamps>('photoTimestamps').then((timestamps) => {
        return timestamps ?? {};
    });
};

export const setLocalPhotoTimestamp = async (hashName: string, time?: string) => {
    return getLocalPhotoTimestamps().then((current) => {
        const newStore: ILocalTimestamps = current ? current : {};
        newStore[hashName] = time ?? dayjs().toISOString();
        return lfContractMetaStore.setItem('photoTimestamps', newStore);
    });
};

export const getContractNumber = (id: string): string => {
    return `IM-${String(id).padStart(7, '0')}`;
};
