import { useCallback } from 'react';
import assert from 'assert';
import useLatest from 'react-use/lib/useLatest';
import { IBLElementShowHideWhen, TBLValue } from './formInterfaces';
import { useBLFormContext } from './context/BLFormContext';
import { IBLVault, IBLVaultGlobal } from './BLVault';
import * as yup from 'yup';

export interface IShowHideConditions {
    hideWhen?: IBLElementShowHideWhen;
    showWhen?: IBLElementShowHideWhen;
}

export const SVaultFile = yup.object().shape({
    global: yup.object().required(),
    showElements: yup.object().required(),
    checkId: yup.string().required().length(21),
    meta: yup.object().shape({
        lastSaved: yup.string().required(),
        progress: yup.number().required(),
    }),
});

export const SPhotoPatches = yup.object().shape({
    id: yup.string().required(),
    x: yup.number().required(),
    y: yup.number().required(),
    width: yup.number().required(),
    height: yup.number().required(),
});

export const SContractPhoto = yup.object().shape({
    slotName: yup.string().required(),
    filePath: yup.string(),
    uploaded: yup.boolean().required(),
    timestamp: yup.string().nullable(),
    label: yup.string().optional(),
    text: yup.string().optional(),
    description: yup.string().optional(),
    denied: yup.boolean().optional(),
    fileMissing: yup.boolean().optional(),
    rotated: yup.number().optional(),
    height: yup.number().optional(),
    width: yup.number().optional(),
    required: yup.boolean().optional(),
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    patches: yup.array().of(SPhotoPatches).optional(),
    wasDeleted: yup.boolean().optional(),
});

export const SContractPhotoVault = yup.object().shape({
    lastSaved: yup.string().required(),
    photos: yup.array().of(SContractPhoto),
});

const useVault = () => {
    const [fields, setFields] = useBLFormContext();
    const latestFields = useLatest(fields);

    const setVaultItem = useCallback(
        (key: string, value: TBLValue, page = 0, global = false) => {
            const vault = { ...latestFields.current };

            if (global) {
                vault.vault.global[key] = value;
            } else {
                // Define Page (Page01 = 1 [not 0])
                const pageKey = String(page);

                // Create page prop in vault if it no exists ,yet
                if (!(pageKey in vault.vault)) {
                    vault.vault[pageKey] = {};
                }

                // Insert key/value data
                const pageVault = vault.vault[pageKey];
                assert.ok(pageVault !== undefined, 'could not find pageVault by pageKey!!');
                pageVault[key] = value;
            }

            setFields(vault);
        },
        [latestFields, setFields]
    );

    const getVaultItem = useCallback(
        (key: string, nullValue: TBLValue = '', page = 0, global = false): TBLValue => {
            const { vault } = latestFields.current;
            if (global) {
                if (key in vault.global) {
                    return vault.global[key];
                }
            } else {
                // Define Page (Page01 = 1 [not 0])
                const pageKey = String(page);

                return vault?.[pageKey]?.[key] ?? nullValue;
            }

            return nullValue;
        },
        [latestFields]
    );

    const insertPages: (afterPage?: number, count?: number) => Promise<number[]> = useCallback(
        // eslint-disable-next-line @typescript-eslint/require-await
        async (afterPage = 1, count = 1): Promise<number[]> => {
            // eslint-disable-next-line no-console
            console.log(`insert page: ${afterPage}`);

            const newVault: IBLVault[] = [];

            const ptr: number[] = [];

            let pages = 0;

            // Object to Array
            Object.keys(fields.vault).forEach((key, index) => {
                if (Number(key) > 0) {
                    newVault[index] = fields.vault[key] as IBLVault;
                }
            });

            // Insert new vault items
            for (let x = 0; x < count; x += 1) {
                newVault.splice(afterPage, 0, {});
                ptr.push(afterPage + x + 1);
            }

            // Assemble new vault
            const finalVault: IBLVaultGlobal = {
                custom: { ...fields.vault.custom },
                global: { ...fields.vault.global },
                meta: {
                    progress: 0,
                },
            };

            // Add pages to new object
            newVault.forEach((page, index) => {
                finalVault[(index + 1).toString()] = page;
                pages += 1;
            });

            // eslint-disable-next-line no-console
            console.log('finalVault', finalVault);
            // eslint-disable-next-line no-console
            console.log('pages', pages);
            // eslint-disable-next-line no-console
            console.log('PTR', ptr);

            setFields({ ...fields, vault: finalVault, pages });

            return ptr;
        },
        [fields, setFields]
    );

    const removePages: (afterPage?: number, count?: number) => Promise<number[]> = useCallback(
        // eslint-disable-next-line @typescript-eslint/require-await
        async (afterPage = 1, count = 1): Promise<number[]> => {
            // eslint-disable-next-line no-console
            console.log(`remove page: ${afterPage}`);

            const newVault: IBLVault[] = [];

            let pages = 0;
            const ptr: number[] = [];

            // Object to Array
            Object.keys(latestFields.current.vault).forEach((key, index) => {
                if (Number(key) > 0) {
                    newVault[index] = latestFields.current.vault[key] as IBLVault;
                }
            });

            // Insert new items
            newVault.splice(afterPage, count);

            // Get PTR to clear
            for (let x = afterPage; x < afterPage + count; x += 1) {
                ptr.push(x + 1);
            }

            // Assemble new vault
            const finalVault: IBLVaultGlobal = {
                custom: { ...latestFields.current.vault.custom },
                global: { ...latestFields.current.vault.global },
                meta: {
                    progress: 0,
                },
            };

            // Add pages to new object
            newVault.forEach((page, index) => {
                finalVault[(index + 1).toString()] = page;
                pages += 1;
            });

            // eslint-disable-next-line no-console
            console.log('finalVault', finalVault);
            // eslint-disable-next-line no-console
            console.log('pages', pages);
            // eslint-disable-next-line no-console
            console.log('PTR', ptr);

            setFields({ ...fields, vault: finalVault, pages });

            return ptr;
        },
        [fields, latestFields, setFields]
    );

    const determineElementIsShown = useCallback(
        (showWhen?: IBLElementShowHideWhen) => {
            if (showWhen !== undefined) {
                // Determine HIDE-SHOW
                // Note: show will win, if set
                let doShow = true;
                const map = new Map(Object.entries(showWhen));

                map.forEach((conditionalValues, itemKey) => {
                    const value = getVaultItem(itemKey, '', 0, true);

                    if (Array.isArray(value)) {
                        if (Array.isArray(conditionalValues)) {
                            const cV = conditionalValues as unknown as string[];
                            cV.forEach((stringValue) => {
                                if (value.includes(stringValue)) {
                                    doShow = false;
                                }
                            });
                        } else {
                            // Sonstige
                            if (!value.includes(conditionalValues)) {
                                doShow = false;
                            }
                        }
                    } else if (typeof value === 'string') {
                        // Ja
                        if (value !== conditionalValues) {
                            doShow = false;
                        }
                    }
                });

                return doShow;
            }
        },
        [getVaultItem]
    );

    const determineElementIsHidden = useCallback(
        (hideWhen?: IBLElementShowHideWhen) => {
            if (hideWhen !== undefined) {
                let doHide = false;
                const map = new Map(Object.entries(hideWhen));

                map.forEach((conditionalValues, itemKey) => {
                    const value = getVaultItem(itemKey, '', 0, true);

                    if (Array.isArray(value)) {
                        if (Array.isArray(conditionalValues)) {
                            const cV = conditionalValues as unknown as string[]; // ["unbebautesGrundstueck"]
                            cV.forEach((stringValue) => {
                                if (value.includes(stringValue)) doHide = true;
                            });
                        } else {
                            if (value.includes(conditionalValues)) doHide = true;
                        }
                    } else if (typeof value === 'string') {
                        if (value === conditionalValues) doHide = true;
                    }
                });

                return doHide;
            }
        },
        [getVaultItem]
    );

    // TODO Deprecated, shall be substituted by determineElementIsHidden + determineElementIsShown
    const determineVisibility = useCallback(
        (conditions: IShowHideConditions = {}, page = 0): boolean => {
            // NOTE: Hide When is stronger than showWhen

            // Determine HIDE-WHEN
            if (conditions.hideWhen !== undefined) {
                let doHide = false;
                const map = new Map(Object.entries(conditions.hideWhen));

                map.forEach((conditionalValues, itemKey) => {
                    const value = getVaultItem(itemKey, '', page, true);

                    if (Array.isArray(value)) {
                        if (Array.isArray(conditionalValues)) {
                            const cV = conditionalValues as unknown as string[];
                            cV.forEach((stringValue) => {
                                if (value.includes(stringValue)) doHide = true;
                            });
                        } else {
                            if (value.includes(conditionalValues)) doHide = true;
                        }
                    } else if (typeof value === 'string') {
                        if (value === conditionalValues) doHide = true;
                    }
                });

                return doHide;
            }

            if (conditions.showWhen !== undefined) {
                // Determine HIDE-SHOW
                // Note: show will win, if set
                let doShow = true;
                const map = new Map(Object.entries(conditions.showWhen));

                map.forEach((conditionalValues, itemKey) => {
                    const value = getVaultItem(itemKey, '', page, true);

                    if (Array.isArray(value)) {
                        if (Array.isArray(conditionalValues)) {
                            const cV = conditionalValues as unknown as string[];
                            cV.forEach((stringValue) => {
                                if (value.includes(stringValue)) {
                                    doShow = false;
                                }
                            });
                        } else {
                            // Sonstige
                            if (!value.includes(conditionalValues)) {
                                doShow = false;
                            }
                        }
                    } else if (typeof value === 'string') {
                        // Ja
                        if (value !== conditionalValues) {
                            doShow = false;
                        }
                    }
                });

                return !doShow;
            }
            return false;
        },
        [getVaultItem]
    );

    const addToRequiredElements = useCallback(
        (id: string) => {
            const vault = { ...latestFields.current };

            const newReqElements = [...vault.requiredElements];

            if (newReqElements) {
                if (!newReqElements.includes(id)) {
                    newReqElements.push(id);
                    setFields({ ...vault, requiredElements: newReqElements });
                }
            }
        },
        [latestFields, setFields]
    );

    const removeFromRequiredElements = useCallback(
        (id: string) => {
            const vault = { ...latestFields.current };

            const newReqElements = [...vault.requiredElements];

            if (newReqElements.includes(id)) {
                newReqElements.splice(newReqElements.indexOf(id), 1);
                setFields({ ...vault, requiredElements: newReqElements });
            }
        },
        [latestFields, setFields]
    );

    return {
        setVaultItem,
        getVaultItem,
        determineHide: determineVisibility,
        insertPages,
        removePages,
        addToRequiredElements,
        removeFromRequiredElements,
        determineElementIsHidden,
        determineElementIsShown,
    };
};

export default useVault;
