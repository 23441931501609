export const isSafari = () => {
    return typeof window === 'undefined' ||
        typeof window.navigator === 'undefined' ||
        typeof window.navigator.userAgent === 'undefined'
        ? false
        : /^((?!chrome|android).)*safari/i.test(window.navigator.userAgent);
};

export const isIOS = () => {
    return (
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
};
